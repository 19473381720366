import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

const NotFound = () => (
  <Layout>
    <SEO title="Página não encontrada" />
    <section className="entre-em-contato top-espaco">
      <div className="container">
        <h2>OOPS!</h2>
        <p>Página não encontrada! <a href="/">Página inicial</a></p>
      </div>
    </section>
  </Layout>
)

export default NotFound